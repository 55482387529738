import { Autocomplete } from 'stimulus-autocomplete';

class CustomAutocomplete extends Autocomplete {
  static targets = ['country'];

  connect() {
    super.connect();

    const countryElement = document.getElementById('country');
    if (countryElement) {
      countryElement.addEventListener('change', (e) => {
        this.updateURL(e);
      });
      if (countryElement.value) this.updateURL(countryElement.value);
    }

    this.addHiddenField();
  }

  updateURL(e) {
    const alpha2 = typeof e == 'object' ? e.currentTarget.value : e;
    const originalURL = this.urlValue.split('?')[0];
    this.urlValue = originalURL + `?country_alpha2=${alpha2}`;
  }

  initialize() {}

  commit(selected) {
    super.commit(selected);

    if (this.hasCountryTarget) {
      this.inputTarget.value = selected.getAttribute(
        'data-autocomplete-location'
      );
      this.countryTarget.value = selected.getAttribute(
        'data-autocomplete-country'
      );
    }
  }

  addHiddenField() {
    if (this.hasHiddenTarget) return;
    const nextNode = this.element.firstElementChild;
    nextNode.setAttribute('data-autocomplete-target', 'hidden');
  }
}

export { CustomAutocomplete };
